import { format } from 'date-fns'

export const monthShort = (date: Date) => format(date, 'LLL')
export const dayOfMonth = (date: Date) => format(date, 'd')
export const dayOfMonthLong = (date: Date) => format(date, 'LLLL d, y')
export const dateLong = (date: Date) => format(date, 'EEEE, LLLL d, y')
export const year = (date: Date) => format(date, 'y')
export const monthYear = (date: Date) => format(date, 'LLLL y')
export const time = (date: Date) => format(date, 'h:mm a')

export const eventTimeRange = (date: Date, durationMinutes?: number | null) => {
  if (!durationMinutes) return time(date)
  const endTime = date.getTime() + 60000 * durationMinutes
  return `${time(date)} ${time(new Date(endTime))}`
}
